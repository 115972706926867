import { Route } from "react-router-dom"
import Events from "../screens/events"
import RegisterForm from "../screens/registerForm"
import LoginForm from "../screens/loginForm"
import Logout from "../screens/logout"
import NotFound from "../screens/notFound"
import Contacts from "../screens/contacts"
import RequestStatus from "../screens/orderStatus"
import OrderTicketsForm from "../screens/ticketsOrder"
import ProtectedRoute from "../components/common/protectedRoute"

export const routes = [
  {
    showInMenu: false,
    path: "/login",
    title: "Logind",
    route: Route,
    component: LoginForm
  },
  {
    showInMenu: false,
    path: "/register",
    title: "Registerer",
    route: Route,
    component: RegisterForm
  },
  {
    showInMenu: false,
    path: "/logout",
    title: "Logout",
    route: Route,
    component: Logout
  },
  {
    showInMenu: true,
    path: "/events",
    title: "Find billetter",
    route: ProtectedRoute,
    component: Events
  },
  {
    showInMenu: false,
    path: "/order-tickets/:id/:sid",
    title: "Billetbestilling",
    route: ProtectedRoute,
    component: OrderTicketsForm
  },
  {
    showInMenu: true,
    path: "/status",
    title: "Anmodninger",
    route: ProtectedRoute,
    component: RequestStatus
  },
  {
    showInMenu: true,
    path: "/contacts/:companyId?/:contactId?",
    url: "/contacts",
    title: "Kontakter",
    route: ProtectedRoute,
    component: Contacts
  },
  {
    showInMenu: false,
    path: "/not-found",
    title: "Side ikke fundet",
    route: Route,
    component: NotFound
  }
]

export const menuItems = routes.filter(r => r.showInMenu)
